import React, { type FC } from 'react';
import { FAQ } from './FAQ';
import type { Locales } from '../../../i18n/ui';
import { Terms } from './Terms';

type Props = {
  lang: Locales[number];
};

export const Modals: FC<Props> = ({ lang }) => {
  return (
    <>
      <FAQ lang={lang} />
      <Terms lang={lang} />
    </>
  );
};
