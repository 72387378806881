import React, { type FC } from 'react';
import { Modal } from '../../Modal';
import { Policy } from '../../Policy';
import type { Locales } from '../../../../i18n/ui';
import type { PolicyKeys } from '../../../../types';
import { POLICIES } from '../../../../constants';

type Props = {
  lang: Locales[number];
};

const termsKeys = [
  { type: 'subtitle', value: 'policy.terms.subtitle.1' },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.1',
    span: 'policy.terms.paragraph.1.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.2',
    span: 'policy.terms.paragraph.2.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.3',
    span: 'policy.terms.paragraph.3.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.4',
    span: 'policy.terms.paragraph.4.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.5',
    span: 'policy.terms.paragraph.5.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.6',
    span: 'policy.terms.paragraph.6.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.7',
    span: 'policy.terms.paragraph.7.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.8',
    span: 'policy.terms.paragraph.8.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.9',
    span: 'policy.terms.paragraph.9.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.10',
    span: 'policy.terms.paragraph.10.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.11',
    span: 'policy.terms.paragraph.11.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.12',
    span: 'policy.terms.paragraph.12.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.13',
    span: 'policy.terms.paragraph.13.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.14',
    span: 'policy.terms.paragraph.14.span',
  },
  { type: 'subtitle', value: 'policy.terms.subtitle.2' },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.15',
    span: 'policy.terms.paragraph.15.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.16',
    span: 'policy.terms.paragraph.16.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.17',
    span: 'policy.terms.paragraph.17.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.18',
    span: 'policy.terms.paragraph.18.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.19',
    span: 'policy.terms.paragraph.19.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.20',
    span: 'policy.terms.paragraph.20.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.21',
    span: 'policy.terms.paragraph.21.span',
  },
  { type: 'paragraph', value: 'policy.terms.paragraph.21.2' },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.22',
    span: 'policy.terms.paragraph.22.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.23',
    span: 'policy.terms.paragraph.23.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.24',
    span: 'policy.terms.paragraph.24.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.25',
    span: 'policy.terms.paragraph.25.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.26',
    span: 'policy.terms.paragraph.26.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.27',
    span: 'policy.terms.paragraph.27.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.28',
    span: 'policy.terms.paragraph.28.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.29',
    span: 'policy.terms.paragraph.29.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.30',
    span: 'policy.terms.paragraph.30.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.31',
    span: 'policy.terms.paragraph.31.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.32',
    span: 'policy.terms.paragraph.32.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.33',
    span: 'policy.terms.paragraph.33.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.34',
    span: 'policy.terms.paragraph.34.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.35',
    span: 'policy.terms.paragraph.35.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.36',
    span: 'policy.terms.paragraph.36.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.37',
    span: 'policy.terms.paragraph.37.span',
  },
  { type: 'subtitle', value: 'policy.terms.subtitle.3' },
  { type: 'subtitle', value: 'policy.terms.subtitle.4' },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.38',
    span: 'policy.terms.paragraph.38.span',
  },
  { type: 'list', value: ['policy.terms.list.1.1', 'policy.terms.list.1.2'] },
  { type: 'subtitle', value: 'policy.terms.subtitle.5' },
  { type: 'subtitle', value: 'policy.terms.subtitle.6' },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.39',
    span: 'policy.terms.paragraph.39.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.40',
    span: 'policy.terms.paragraph.40.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.41',
    span: 'policy.terms.paragraph.41.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.42',
    span: 'policy.terms.paragraph.42.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.43',
    span: 'policy.terms.paragraph.43.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.44',
    span: 'policy.terms.paragraph.44.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.45',
    span: 'policy.terms.paragraph.45.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.46',
    span: 'policy.terms.paragraph.46.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.47',
    span: 'policy.terms.paragraph.47.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.48',
    span: 'policy.terms.paragraph.48.span',
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.49',
    span: 'policy.terms.paragraph.49.span',
  },
  { type: 'paragraph', value: 'policy.terms.paragraph.49.2' },
  { type: 'subtitle', value: 'policy.terms.subtitle.7' },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.50',
    span: 'policy.terms.paragraph.50.span',
  },
  {
    type: 'list',
    value: [
      'policy.terms.list.2.1',
      'policy.terms.list.2.2',
      'policy.terms.list.2.3',
      'policy.terms.list.2.4',
      'policy.terms.list.2.5',
    ],
  },
  {
    type: 'paragraph-with-span',
    value: 'policy.terms.paragraph.51',
    span: 'policy.terms.paragraph.51.span',
  },
  {
    type: 'list',
    value: ['policy.terms.list.3.1', 'policy.terms.list.3.2'],
  },
  {
    type: 'paragraph-with-span',
    span: 'policy.terms.paragraph.52.span',
  },
  {
    type: 'list',
    value: [
      'policy.terms.list.4.1',
      'policy.terms.list.4.2',
      'policy.terms.list.4.3',
      'policy.terms.list.4.4',
      'policy.terms.list.4.5',
    ],
  },
  {
    type: 'paragraph-with-span',
    span: 'policy.terms.paragraph.53.span',
  },
  {
    type: 'list',
    value: [
      'policy.terms.list.5.1',
      'policy.terms.list.5.2',
      'policy.terms.list.5.3',
      'policy.terms.list.5.4',
      'policy.terms.list.5.5',
      'policy.terms.list.5.6',
      'policy.terms.list.5.7',
      'policy.terms.list.5.8',
    ],
  },
] as PolicyKeys;

export const Terms: FC<Props> = ({ lang }) => {
  return (
    <Modal id={POLICIES.TERMS}>
      <Policy text={termsKeys} title='policy.terms.title' lang={lang} />
    </Modal>
  );
};
