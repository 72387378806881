import type { Settings } from '../../types';
import { api } from '../../utils';
import { commonsStore } from '../commons';

export const fetchCommons = async ({ settings }: { settings: Settings }) => {
  const query = btoa(
    JSON.stringify({
      models: 'countries,currencies,languages',
      extras: 'clientdatas',
    }),
  );

  const { static_dictionaries_version } = settings;
  try {
    const { data, status } = await api.get(
      `/combine${query}.json?${static_dictionaries_version}`,
    );

    if (status === 200) {
      commonsStore.set(data);

      return data;
    }

    return 'Error';
  } catch (error) {
    console.error('Failed to fetch:', error);
  }
};
