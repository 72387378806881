import React, { type FC } from 'react';

import styles from './policy.module.scss';
import type { Locales, UILocalesKeys } from '../../../i18n/ui';
import { useTranslations } from '../../../i18n/utils';
import type { PolicyKeys } from '../../../types';

type Props = {
  title: UILocalesKeys;
  lang: Locales[number];
  text: PolicyKeys;
};

export const Policy: FC<Props> = ({ title, lang, text }) => {
  const t = useTranslations(lang);
  return (
    <div className={styles.policy}>
      <h2 className={styles.title}>{t(title)}</h2>
      {text.map((item, index) => {
        switch (item.type) {
          case 'subtitle':
            return (
              <h3 key={index} className={styles.subtitle}>
                {t(item.value)}
              </h3>
            );
          case 'list':
            return (
              <ul key={index} className={styles.list}>
                {item.value.map((listItem, idx) => {
                  if (listItem === 'policy.terms.list.2.3') {
                    return (
                      <li key={idx} className={styles.item}>
                        {t(listItem)}{' '}
                        <a
                          className={styles.link}
                          href='mailto:partner@valor.partners'
                        >
                          partner@valor.partners
                        </a>
                      </li>
                    );
                  }
                  return (
                    <li key={idx} className={styles.item}>
                      {t(listItem)}
                    </li>
                  );
                })}
              </ul>
            );
          case 'paragraph-with-span':
            return (
              <p key={index} className={styles.text}>
                <span>{t(item.span)} </span>
                {t(item.value)}
              </p>
            );
          default:
            return (
              <p key={index} className={styles.text}>
                {t(item.value)}
              </p>
            );
        }
      })}
    </div>
  );
};
