export const CloseIcon = () => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line
        x1='1'
        y1='-1'
        x2='19.5183'
        y2='-1'
        transform='matrix(0.682318 0.731055 -0.682318 0.731055 0 2)'
        stroke='#FCB223'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='1'
        y1='-1'
        x2='19.5183'
        y2='-1'
        transform='matrix(-0.682318 0.731055 0.682318 0.731055 16 2)'
        stroke='#FCB223'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};
