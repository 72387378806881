import React, { useEffect, useMemo, type FC, type ReactNode } from 'react';
import classNames from 'classnames';
import { useStore } from '@nanostores/react';

import { showModalsStore } from '../../../store';
import styles from './modal.module.scss';
import { CloseIcon } from './CloseIcon';
import type { PoliciesKeys } from '../../../constants';

type Props = {
  id: PoliciesKeys;
  children: ReactNode;
};

export const Modal: FC<Props> = ({ id, children }) => {
  const $showModalsStore = useStore(showModalsStore);

  const isOpen = useMemo(() => {
    return $showModalsStore.includes(id);
  }, [id, $showModalsStore]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (isOpen) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    showModalsStore.set($showModalsStore.filter((item) => item !== id));
  };

  return (
    <div
      onClick={handleClose}
      className={classNames(styles.overlay, { [styles.overlay_show]: isOpen })}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.modal}>
        <button onClick={handleClose} className={styles.close}>
          <CloseIcon />
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};
