import React, { useEffect, useState } from 'react';
import { useStore } from '@nanostores/react';

import {
  clientDatasStore,
  commonsStore,
  fetchClientData,
  fetchCommons,
} from '../../../store';
import { localesKeys } from '../../../i18n/ui';

export const Global = () => {
  const [langReady, setLangReady] = useState(false);
  const $clientDatasStore = useStore(clientDatasStore);
  const $commonsStore = useStore(commonsStore);

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    const lsLang = localStorage.getItem('lang');
    if ($clientDatasStore) {
      if (!lsLang) {
        localStorage.setItem(
          'lang',
          $clientDatasStore.country.default_language_code,
        );
      }

      setLangReady(true);

      fetchCommons({ settings: $clientDatasStore.settings });
    }
  }, [$clientDatasStore]);

  useEffect(() => {
    const lsLang = localStorage.getItem('lang') || 'en';
    const pathname = window?.location?.pathname;
    if (langReady && pathname) {
      if (localesKeys.includes(lsLang)) {
        if (pathname === '/' && lsLang === 'en') {
          return;
        }
        if (!pathname.includes(lsLang)) {
          window.location.pathname = lsLang === 'en' ? '/' : `/${lsLang}`;
        }
      }
    }
  }, [langReady]);

  useEffect(() => {
    const globalLoader = document.getElementById('global-loader');
    if ($commonsStore && globalLoader) {
      globalLoader.style.display = 'none';
    }
  }, [$commonsStore]);

  return <div />;
};
