import React, { type FC } from 'react';
import { Modal } from '../../Modal';
import { Policy } from '../../Policy';
import type { Locales } from '../../../../i18n/ui';
import type { PolicyKeys } from '../../../../types';
import { POLICIES } from '../../../../constants';

type Props = {
  lang: Locales[number];
};

const faqKeys = [
  { type: 'subtitle', value: 'policy.faq.subtitle.1' },
  { type: 'paragraph', value: 'policy.faq.paragraph.1' },
  { type: 'subtitle', value: 'policy.faq.subtitle.2' },
  { type: 'paragraph', value: 'policy.faq.paragraph.2' },
  { type: 'subtitle', value: 'policy.faq.subtitle.3' },
  { type: 'paragraph', value: 'policy.faq.paragraph.3' },
  { type: 'subtitle', value: 'policy.faq.subtitle.4' },
  { type: 'paragraph', value: 'policy.faq.paragraph.4' },
  { type: 'list', value: ['policy.faq.list.1', 'policy.faq.list.2'] },
  { type: 'subtitle', value: 'policy.faq.subtitle.5' },
  { type: 'paragraph', value: 'policy.faq.paragraph.5' },
  { type: 'subtitle', value: 'policy.faq.subtitle.6' },
  { type: 'paragraph', value: 'policy.faq.paragraph.6' },
] as PolicyKeys;

export const FAQ: FC<Props> = ({ lang }) => {
  return (
    <Modal id={POLICIES.FAQ}>
      <Policy text={faqKeys} title='policy.faq.title' lang={lang} />
    </Modal>
  );
};
