import { api } from '../../utils';
import { clientDatasStore } from '../clientData';

export const fetchClientData = async () => {
  try {
    const { data, status } = await api.get('/clientdatas');

    if (status === 200) {
      clientDatasStore.set(data);

      return data;
    }

    return 'Error';
  } catch (error) {
    console.error('Failed to fetch:', error);
  }
};
