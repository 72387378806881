import React, { type FC } from 'react';

import classNames from 'classnames';
import { useStore } from '@nanostores/react';

import type { Locales, UILocalesKeys } from '../../../i18n/ui';
import type { PoliciesKeys } from '../../../constants';
import { useTranslations } from '../../../i18n/utils';
import { showModalsStore } from '../../../store';

import styles from './policyButton.module.scss';

type Props = {
  lang: Locales[number];
  label: UILocalesKeys;
  id: PoliciesKeys;
  className?: string;
};

export const PolicyButton: FC<Props> = ({ label, lang, id, className }) => {
  const $showModalsStore = useStore(showModalsStore);
  const t = useTranslations(lang);

  const handleClick = () => {
    showModalsStore.set([id]);
  };

  return (
    <button
      onClick={handleClick}
      className={classNames(styles.policy, className)}
    >
      {t(label)}
    </button>
  );
};
